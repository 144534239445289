import { useState, useEffect } from "react";
import youtubeApi, { KEY } from "../apis/youtube.js";

const useVideos = (defaultSearchTerm) => {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    search(defaultSearchTerm);
  }, [defaultSearchTerm]);

  const search = async (searchText) => {
    if (searchText === "") {
      setVideos([]);
      return;
    }

    const response = await youtubeApi.get("/search", {
      params: {
        part: "snippet",
        maxResults: 25,
        key: KEY,
        type: "video",
        q: searchText,
      },
    });

    setVideos(response.data.items);
  };

  // both the ways are good to go.

  //   return {
  //     videos,
  //     onSearchSubmit,
  //   };
  return [videos, search];
};

export default useVideos;
