import React, { useState } from "react";

const SearchBar = ({ onSubmit }) => {
  const [searchText, setSearchText] = useState("");

  const onFormSubmit = (e) => {
    e.preventDefault();

    onSubmit(searchText);
  };

  return (
    <div className="search-bar ui segment">
      <form className="ui form" onSubmit={onFormSubmit}>
        <div className="ui field">
          <label>Search for a Video</label>
          <input
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
      </form>
    </div>
  );
};

export default SearchBar;
