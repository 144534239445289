import React from "react";
import VideoItem from "./VideoItem";

const VideoList = ({ videos, onVideoSelected }) => {
  const videoItems = videos.map((video) => {
    return (
      <VideoItem
        key={video.etag}
        video={video}
        onVideoSelected={onVideoSelected}
      />
    );
  });

  return (
    <div className="videos-list">
      <span>Total Search Results: {videos.length}</span>
      <div className="ui relaxed divided list">{videoItems}</div>
    </div>
  );
};

export default VideoList;
